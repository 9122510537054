import Product from './components/Product'
import  data from './data/database.json'
import './App.css';

function App() {
  return (
    <main>
      {data.map((item)=> <Product key={item.name} text={item}/>)}
    </main>
  );
}

export default App;
