import React from 'react'
import Stack  from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Typography, Divider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#1b5e20', // Change this to your desired color
      },
      secondary: {
        main: '#e65100', // Change this to your desired color
      }
    },
  });

type TextData = {
    text: {
        name: string;
        description: string[];
        price: number;
        image: string;
      };
};



const Product: React.FC<TextData> = ({ text }: TextData) => {

  return (
    <ThemeProvider theme={theme}>
    <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="center"
    >
        <Box
            height={200}
            component="img"
            src={text.image}
            alt="product"
        />
        <Stack direction="column" spacing={2} width={200}>
        <Typography variant="body1" color="primary">{text.name}</Typography>
        <Typography variant="body1">{text.description}</Typography>
        <Typography variant="body1" color="secondary">{text.price}</Typography>
        </Stack>

      
    </Stack>
    <Divider />
    </ThemeProvider>
  )
}

export default Product
